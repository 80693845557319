/*!
Template :	CryptoCoin @by Softnio
Preset   :	Default
Version	 :	1.0.1
Updated	 :	01.29.2018
*/

h1.color-secondary,
h2.color-secondary,
h3.color-secondary,
h4.color-secondary,
h5.color-secondary,
h6.color-secondary,
.h1.color-secondary,
.h2.color-secondary,
.h3.color-secondary,
.h4.color-secondary,
.h5.color-secondary,
.h6.color-secondary {
  color: #011050;
}
h1.color-primary,
h2.color-primary,
h3.color-primary,
h4.color-primary,
h5.color-primary,
h6.color-primary,
.h1.color-primary,
.h2.color-primary,
.h3.color-primary,
.h4.color-primary,
.h5.color-primary,
.h6.color-primary {
  color: #011050;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color:white;
}

.heading-section.color-secondary {
  color: #011050;
}
.heading-section.color-primary {
  color: #011050;
}

.heading-sm-lead.color-primary,
.heading-md-lead.color-primary {
  color: #011050;
}
.heading-sm-lead.color-secondary,
.heading-md-lead.color-secondary {
  color: #011050;
}

.with-line::after {
  background-color: #011050;
}

blockquote p:last-child strong {
  color: #011050;
}

a {
  color: white;
}
a:hover,
a:focus,
a:active {
  color: #009cfe;
}

.color-primary {
  color: #011050;
}

.color-secondary {
  color: #011050;
}

.bg-primary {
  background: #011050;
}

.bg-secondary {
  background: #011050;
}

.bg-light-primary {
  background-color: rgba(247, 146, 26, 0.1);
}

.bg-light-secondary {
  background-color: rgba(247, 146, 26, 0.1);
}

.accordion .panel-title a {
  color: #011050;
}
.accordion.filled .panel-title a {
  color: #011050;
}

.icon-box {
  color: #011050;
}
.icon-box.sqaure,
.icon-box.round,
.icon-box.circle {
  border-color: #011050;
}
.icon-box.icon-bg {
  background-color: #011050;
}
.icon-box.icon-bg-alt {
  background-color: #011050;
}
.icon-box.light.icon-bg {
  color: #011050;
}
.icon-box.style-s1,
.icon-box.style-s2 {
  background-color: #011050;
}
.icon-box.style-s2 {
  background-color: #011050;
}
.icon-box.style-s3,
.icon-box.style-s4 {
  border-color: #011050;
  color: #011050;
}
.icon-box.style-s4 {
  border-color: #011050;
  color: #011050;
}

.image-slice .section-bg:not(.section-bg-r):after {
  background: #011050;
}
.image-slice .section-bg.section-bg-alt:not(.section-bg-r):after {
  background: #011050;
}

.has-bg.bg-primary:after {
  background-color: #011050;
}
.has-bg.bg-secondary:after {
  background-color: #011050;
}

.tab-custom .nav-tabs li:last-child a {
  border-right-color: #011050;
}
.tab-custom .nav-tabs li > a {
  border: 1px solid #011050;
}
.tab-custom .nav-tabs li.active > a,
.tab-custom .nav-tabs li.active > a:hover,
.tab-custom .nav-tabs li.active > a:focus,
.tab-custom .nav-tabs li > a:hover,
.tab-custom .nav-tabs li > a:focus {
  background: #011050;
  border-color: #011050;
}

.feature-intro a.btn-link {
  color: #011050;
}

.owl-dots .owl-dot {
  border: 1px solid #011050;
}
.owl-dots .owl-dot.active {
  background: #011050;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  color: #011050;
  opacity: 1;
}
.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next:hover {
  background: #011050;
}

.copyright .social li a:hover,
.copyright .social li a:focus {
  color: #009CFE;
}

.pagination li.active a {
  background: #011050;
  border-color: #011050;
}
.pagination li a {
  color: #011050;
}
.pagination li a:hover,
.pagination li a:focus {
  background: #011050;
  border-color: #011050;
}

.site-header .quote-btn .btn {
  background-color: #011050;
  border: 2px solid #011050;
}
.site-header .quote-btn .btn:hover {
  border-color: #011050;
  color: #011050;
}

.topbar .social li a:hover {
  color: #009CFE;
}

.top-nav a:hover,
.top-nav a:focus {
  color: #011050;
}

.top-contact .fa {
  border: 1px solid #011050;
  color: #011050;
}
.top-contact a:hover {
  color: #009CFE  ;
}

.header-top .social li a {
  color: #011050;
}
.header-top .social.social-s2 li a {
  background: #011050;
  border: 1px solid #011050;
}

.social.social-s2 li a {
  background: #011050;
  border: 1px solid #011050;
}
.social.social-s2 li a:hover {
  color: #011050;
}
.social.social-v2 li {
  border: 1px solid #011050;
}

.navbar-nav > li.active > a,
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  color: #707070;
  background: transparent;
}
.navbar-nav .dropdown-menu li:hover > a {
  color: white;
}
.navbar-nav .dropdown-menu li:hover.has-children:after {
  border-left-color: #009CFE;
}
.navbar-nav .dropdown-menu.active > a,
.navbar-nav .dropdown-menu li > a:hover,
.navbar-nav .dropdown-menu li > a:focus {
  color: #009CFE;
}
.navbar-nav .dropdown-menu > .active > a,
.navbar-nav .dropdown-menu > .active > a:focus,
.navbar-nav .dropdown-menu > .active > a:hover {
  color: #009CFE
}

.site-header.is-transparent .topbar a:not(.btn):hover {
  color: #011050;
}
.site-header.is-transparent .topbar .social li a {
  color: #011050;
}
.site-header.is-transparent .dropdown-menu li:hover.has-children:after {
  border-left-color: #011050;
}
.site-header.is-transparent.has-fixed .navbar-nav > li.active > a,
.site-header.is-transparent.has-fixed .navbar-nav > li > a:hover,
.site-header.is-transparent.has-fixed .navbar-nav > li > a:focus {
  color: #011050;
}
.site-header.is-transparent.has-fixed
  .navbar-nav
  > li
  > a
  .navbar-nav
  > li
  > a:not(.btn)::after {
  background-color: #011050;
}
.site-header.header-dark .topbar {
  background: #ef8608;
  color: #fff;
}
.site-header.header-dark .topbar .icon,
.site-header.header-dark .topbar .fa,
.site-header.header-dark .topbar a:hover {
  color: #011050;
}
.site-header.header-dark .navbar {
  background: #011050;
}
.site-header.header-s2 .navbar-nav li:hover > a {
  background: #009CFE;
}
.site-header.header-s2 .navbar-nav li a:hover,
.site-header.header-s2 .navbar-nav li a:focus {
  background: #011050;
}
.site-header.header-s2 .navbar-nav li.active > a {
  background: #011050;
}
.site-header.header-s2 .navbar-nav li.quote-btn .btn {
  background: #011050;
  border-color: #011050;
}
.site-header.header-s2 .navbar-nav li.quote-btn .btn:hover {
  border-color: #cd7207;
  background: #cd7207;
}
.site-header.header-s2 .navbar-nav .dropdown-menu li:hover > a {
  color: #009CFE;
}
.site-header.header-s2 .navbar-nav .dropdown-menu li a:hover {
  color: #009CFE;
}
.site-header.header-s2 .navbar-nav .dropdown-menu .active a:hover,
.site-header.header-s2 .navbar-nav .dropdown-menu .active a:focus {
  color: #009CFE;
}
.site-header.header-s2 .navbar-collapse ul.nav {
  background: #e0600c;
}

.breadcrumb li:not(.active) a:hover {
  color: #011050;
}

@media only screen and (max-width: 991px) {
  .navbar-toggle {
    background-color: #011050;
  }
  .navbar-header {
    background: #011050;
  }
  .navbar-nav li .dropdown-menu li a:hover,
  .navbar-nav li .dropdown-menu li a:focus {
    color: #011050;
  }
  .navbar-nav li .dropdown-menu > .active > a,
  .navbar-nav li .dropdown-menu > .active > a:hover,
  .navbar-nav li .dropdown-menu > .active > a:focus {
    color: #011050;
  }
  .navbar-nav > li.active a,
  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus {
    color: #011050;
  }
  .site-header .navbar-collapse {
    color: #011050;
  }
  .site-header.is-transparent .navbar-nav > li.active > a,
  .site-header.is-transparent .navbar-nav > li > a:hover,
  .site-header.is-transparent .navbar-nav > li > a:focus {
    color: #011050;
  }
  .site-header.header-s2 .top-bar-wrap {
    border-bottom: 1px solid #fff1e0;
  }
  .site-header.header-s2 .navbar-collapse ul.nav {
    background: #fff;
  }
}
.wgs-box a:hover,
.wgs-box a:focus {
  color: #009CFE;
}
.wgs-box .wgs-search .form-group .search-btn:hover {
  color: #009CFE;
}
.wgs-box .tag-list li a:hover {
  border-color: #009CFE;
}

.sidebar-right a:hover,
.sidebar-right a:focus {
  color: #011050;
}

.btn {
  border: 2px solid #011050;
  background-color: #011050;
}
.btn:hover,
.btn:focus {
  border-color: #011050;
}
.btn.btn-alt {
  background: #011050;
  border-color: #011050;
}
.btn.btn-alt:hover,
.btn.btn-alt:focus {
  border-color: #011050;
}
.btn.btn-outline:hover,
.btn.btn-outline:focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-outline.btn-alt:hover,
.btn.btn-outline.btn-alt:focus {
  color: #011050;
}
.btn.btn-outline.btn-light:hover,
.btn.btn-outline.btn-light:focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-outline.btn-light.btn-alt:hover,
.btn.btn-outline.btn-light.btn-alt:focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-outline.btn-color {
  color: #011050;
  border-color: #011050;
}
.btn.btn-outline.btn-color:hover,
.btn.btn-outline.btn-color:focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-outline.btn-color.btn-alt:hover,
.btn.btn-outline.btn-color.btn-alt:focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-light:not(.btn-outline) {
  color: #011050;
}
.btn.btn-light:not(.btn-outline):hover,
.btn.btn-light:not(.btn-outline):focus {
  background: #011050;
  border-color: #011050;
}
.bg-primary .btn.btn-light:not(.btn-outline):hover,
.bg-primary .btn.btn-light:not(.btn-outline):focus {
  background: #011050;
  border-color: #011050;
}
.btn.btn-light:not(.btn-outline).btn-alt {
  color: #011050;
}
.btn.btn-light:not(.btn-outline).btn-alt:hover,
.btn.btn-light:not(.btn-outline).btn-alt:focus {
  background: #011050;
  border-color: #011050;
}
.bg-secondary .btn.btn-light:not(.btn-outline).btn-alt:hover,
.bg-secondary .btn.btn-light:not(.btn-outline).btn-alt:focus {
  background: #011050;
  border-color: #011050;
}

.btn-link {
  color: #011050;
}
.btn-link:hover {
  color: #009CFE;
}

.btn-icon {
  background: #011050;
}

.box.box-alt-bg {
  background: #fff1e0;
}
.box.box-small-dot:hover {
  background: #596dc6;;
  color: white !important;
}

.box.box-small-dot .font-icon:before {
  background: #011050;
}

.box-alt .pe {
  color: #011050;
}
.box-alt.box-dot .image-icon:before {
  background: #011050;
}

.tab-custom .active .box-alt h5 {
  color: white;
}

.box-side-icon .pe {
  color: #011050;
}

.box-miner h4,
.box-miner .box-miner-title {
  background: #011050;
}
.box-miner .box-miner-action {
  background: rgba(247, 146, 26, 0.02);
}
.box-miner .box-miner-action:hover {
  background: #009CFE;
}

.pricing-box {
  background: #fff1e0;
}
.pricing-box.highlited {
  background: #011050;
}
.pricing-box .pricing-sap:before {
  background: #011050;
}
.pricing-box .pricing-sap:after {
  background: #011050;
}

.text-block h2 span {
  color: #011050;
}
.text-block ul li:before {
  color: #011050;
}

.icon-block ul li:before {
  color: #011050;
}

.video-block .video-play {
  background: #011050;
}
.video-block .video-play:hover,
.video-block .video-play:focus {
  color: #011050;
}

.team-member .team-thumb .team-social li a:hover {
  color: white;
  /* border-color: #011050; */
}
.team-member:hover .team-info {
  background: #009CFE;
}
.team-member .team-info .team-name {
  color: #ffffff;
}

.testimonial-item .client-photo .fa {
  color: #011050;
}
.testimonial-item.testimonial-s2 .client-photo {
  border-color: #011050;
}

.blog-post .post-entry h5 a:hover {
  color: white;
}

.post-single ul li:before {
  color: #011050;
}

.steps .step-number {
  background: #011050;
}

.active .steps h4 {
  color: #011050;
}

.cta-small {
  background: #011050;
}

.contact-information .contact-entry h6 span {
  color: #011050;
}

.p-20 {
  padding: 20px;
}

.custom-background {
  background-color: #fff1e0;
}

.m-10 {
  margin: 10px;
}

.custom-box {
  /* background-color: white; */
  border-radius: 10px;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  /* padding: 50px; */
}

.mt-4 {
  margin-top: 40px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
 
}

.overlayText{
  color: white;
}

.offset-right {
  margin-right: 8.33333333%;
}

.headerContainer {
  position: absolute;
  /* padding: 25px; */
  /* max-width: 820px; */
  /* margin: 30px; */
  margin-bottom: 0%;
  width: 100%;
  text-align: center;
  top: 40%;
  z-index: 4;
}

.customHeader {
  font-family: "Bebas Neue", cursive !important;
  font-size: 5em !important;
}

.customFont{
  font-family: 'Anton', sans-serif !important;
  font-size: 100px;
  color: #011050;
}

h1.box{
  color: #011050;
}
h1.box:hover{
  color:white
}



.customSubheaders{

}

.mapPad{
  padding-top: 50px;
padding-bottom: 70px;
}

.videoPlayer {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  left: 0;
  background-size: cover; 
  /* background-position: 50% 50%; */
  background-repeat: no-repeat;
  z-index: 0;
  /* opacity: 0;  */
  /* transition: opacity .4s; */
}

.videoChange {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgFormatHeader{
 width: 100%;
  height: 692px;
  background-repeat: no-repeat;
}

.cremDescription{
/* background-image: url('../../images/firstBackground.svg') ; */
background-repeat: no-repeat;
background-size: cover;
top: 1079px;
left: -12px;
width: 100%;
height: 100%;

}

.cremDescriptionHeader{
text-align: center;
}

.cremBoxHeader{
margin-top: 10%;
}

a{
  color:#707070;
}

.descriptionBox{
  display: flex;
}

.textOverImageContainer{
  position: relative;
  text-align: center;
  color: white;
  margin-top: 70px;

}

.teamImg{
  min-width:  262.5px !important;
  min-height: 262.5px !important;
  
  max-width: 262.5px !important;
  max-height: 262.5px !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customImage{
  width: 700px;
  padding-bottom: 10px;
}


.blueFont{
  color: #009cfe !important;
}

.customBg {
  background-color: #f8f8f8;
}

.footerBg{
  background-repeat: no-repeat;
    background-size: cover;
}

.featuresCustom{
  margin-top: 50px;
  
}
#coHosting {
  scroll-margin-top: 80px;
}
#realEstate {
  scroll-margin-top: 60px;
}
#energy {
  scroll-margin-top: 60px;
}
#texas {
  scroll-margin-top: 60px;
}
#contact {
  scroll-margin-top: 60px;
}

#mining {
  scroll-margin-top: 60px;
}

.bannerTheme{
  min-height: 550px;
  height: 100vh;
  /* max-height: 850px; */
}
.custom-p{
  font-size: 23px ;
  color:#707070;
  font-weight: 800;

}

.custom-p{
  font-size: 23px ;
  color:#707070;
  font-weight: 800;
}

#coHosting .small{
  font-size: 85%;
}

#realEstate  p{
  font-size: 23px ;
  color:#707070;
  font-weight: 800;

}
#energy  p{
  font-size: 23px ;
  color:#707070;
  font-weight: 800;

}
#texas  p{
  font-size: 23px ;
  
  font-weight: 800;

}
#mining  p{
  font-size: 23px ;
  color:#707070;
  font-weight: 800;

}
.custom-center{

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  
}
#realEstate ul li{
  list-style-type: disc !important;
  font-size: 1.1em;
}

/* media queries */
@media only screen and (max-width: 600px) {
  #texas  p{
    font-size: 7px ;
    
    font-weight: 800;
  
  }
}
@media screen and (min-width: 1440px) {

  .cremtexDescription{
  padding-left: 200px;
  padding-right: 200px;
}

/* media queries */
@media only screen and (max-width: 600px) {

  #texas  p{
    font-size: 7px ;
    
    font-weight: 800;
  
  }
}

@media screen and (min-width: 1440px) {

  .cremtexDescription{
  padding-left: 200px;
  padding-right: 200px;}
}



@media (min-width: 320px) and (max-width:480px) {


  .mobile-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 50%;
  }

  .box-icon{
    display: flex;
    align-items: center;
  }

  .ucap, small{
    text-align: center;
  }

}



  /* #realEstate img,
  #mining img {
    max-width: 80% !important;
  }

  .videoChange {
    height: 1360px;
  }
  .overlay {
    height:1360px;
    max-height: 1360px;
  }

  .CREM{
    padding-top: 800px;
  }

  .headerContainer{
    bottom: 20%;
   top: 40% !important;
    position: absolute;
  }

  .offset-right{
    margin-left: 8.33333333%;
  } */

}
